import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './scss/tile.module.scss';
import { Icon } from '@pulselive/core-product-components';

/**
 * @param props
 */
export default function Tile(props) {
    const { path, name, description, IconComponent } = props;

    return (
        <Link to={path} className={styles.tile}>
            <IconComponent className={styles.tileIcon} />
            <h2 className={styles.tileName}>{name}</h2>
            <p className={styles.tileDescription}>{description}</p>
            <span className={styles.tileArrow}>
                <Icon type="arrow-right" />
            </span>
        </Link>
    );
}

Tile.propTypes = {
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    IconComponent: PropTypes.string.isRequired
};
