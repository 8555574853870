import './set-public-path';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import ParcelComponent from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import Home from './components/home.component';
import { closeLaunchDarklyClient } from '@pulselive/launchdarkly-sdk-wrapper';

/**
 * @param props
 */
export default function Root(props) {
    useEffect(() => {
        return () => {
            closeLaunchDarklyClient();
        };
    }, []);

    return (
        <>
            <ParcelComponent
                config={System.import(
                    '@pulselive/core-product-components'
                ).then((widget) => widget.NotificationsParcel)}
                mountParcel={mountRootParcel}
            />

            <BrowserRouter>
                <Route path="/" exact>
                    <Home routes={props.applicationRoutes} />
                </Route>
            </BrowserRouter>
        </>
    );
}

// type validation
Root.propTypes = {
    /**
     * Array of objects used to construct the nav items
     */
    applicationRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            pathname: PropTypes.string
        })
    )
};
